<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>My Team</template>
      </TopBar>
    </template>
    <template v-slot:content>

    </template>
  </Workspace>
</template>

<script>
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";

export default {
name: "MyTeam",
  components:{Workspace, TopBar}
}
</script>

<style scoped>

</style>